<template>
  <div id1="app">


    <div id="nav">
         <div class="container mt-4">
      <div class="row justify-content-md-center">
        <div class="col-md-auto">
          <h3>Добро пожаловать</h3>
          <span>
            На сайте <a class="" href="https://joldo.kg">JOLDO.kg</a> мы публикуем информацию, которая поможет всем желающим лучше понимать Правила дорожного движения
            Кыргызской Республики.   Так как количество ДТП не становится меньше, важно хорошо разобраться в Правилах дорожного движения, прежде чем получать водительское
            удостоверение. Для этой цели мы подготовили несколько видов онлайн-тестов. Для того чтобы подготовиться к теоретическому экзамену вы можете воспользоваться
            нашим сайтом.   
          </span>
        </div>
      </div>
    </div> <div class="button-row">
        <router-link to="/bilet/ru" class="nav-button">По билетам (RU)</router-link>
        <router-link to="/topic/ru" class="nav-button">По темам (RU)</router-link>
        <router-link to="/exam/ru" class="nav-button">Экзамен (RU)</router-link>
      </div>
      <div class="button-row">
        <router-link to="/bilet/kg" class="nav-button">По билетам (KG)</router-link>
        <router-link to="/topic/kg" class="nav-button">По темам (KG)</router-link>
        <router-link to="/exam/kg" class="nav-button">Экзамен (KG)</router-link>
      </div>
    </div>
    <router-view />
    <Content />
    <Footer />
  </div>
</template>

<script>
export default {
  components: {
    Header: () => import("@/components/Header"),
    Content: () => import("@/components/Content"),
    Footer: () => import("@/components/Footer"),
  },
  data() {
    return {
      needUpgdade: false,
      versionInterval: null,
      actualVersion: null,
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style>
#nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.button-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.nav-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  border: 2px solid #007bff;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  text-align: center;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #0056b3;
}
</style>
